<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/images/logo.svg" alt="Choiz logo" class="mb-7 w-9rem flex-shrink-0">
        <div style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(130, 67, 242, 0.4) 10%, rgba(33, 150, 243, 0) 30%);">
            <div class="w-full surface-card py-8 px-5 sm:px-8 flex flex-column align-items-center" style="border-radius:53px">
                <h1 class="text-900 font-bold text-3xl lg:text-5xl mb-2">Not Found</h1>
                <div class="text-600 mb-5">Recurso solicitado no encontrado.</div>
                <div class="flex align-items-center w-full justify-content-evenly h-16rem">
                    <p class="mb-2 choiz">4</p> <!-- font-bold  -->
                    <img src="assets/images/notFound.png" alt="Choiz logo" class="mb-3 w-6rem flex-shrink-0">
                    <p class="mb-2 choiz">4</p>
                </div>
                
            </div>
        </div>
    </div>
</div>